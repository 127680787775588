<script setup lang="ts">
import type { CSSProperties } from 'vue';

import { M, L, A, Z } from '~/utils/svg';

export type CornerPosition = 'tl' | 'tr' | 'br' | 'bl';

type Corner<T> = { type: T };

type RoundCorner = Corner<'round'> & { size: number };
type AngleCorner = Corner<'angle'> & (
    { width: number, height: number, triangle?: number }
    | { size: number, triangle?: number }
);

type AnyCorner = RoundCorner | AngleCorner;

export type Corners = Partial<Record<CornerPosition, AnyCorner>>;

type Border<T> = { type: T };

type SolidBorder = Border<'solid'> & { width: number };
type DashedBorder = Border<'dashed'> & { width: number, gap: number };

export type AnyBorder = SolidBorder | DashedBorder;

export interface ClipContainerProps {
    corners?: Corners;
    border?: AnyBorder;
    clip?: 'content' | 'border';
    borderShapeRendering?: 'auto' | 'crispEdges' | 'geometricPrecision';

    backgroundStyle?: CSSProperties;
    backgroundPathStyle?: CSSProperties;
    borderStyle?: CSSProperties;
    borderPathStyle?: CSSProperties;
    triangleStyle?: CSSProperties;
    trianglePathStyle?: CSSProperties;
}

const borderColorVariable = '--border-color';
const backgroundColorVariable = '--background-color';
const triangleColorVariable = '--triangle-color';

const props = defineProps<ClipContainerProps>();

const scaleStore = useScaleStore();

const container = ref<HTMLElement | null>(null);

const bounds = useElementSize(container);

const width = computed(() => bounds.width.value);
const height = computed(() => bounds.height.value);

const getCornerSize = (corner: AnyCorner | undefined, padding: number, scale: number): { width: number; height: number } => {
    if (!corner) return { width: 0, height: 0 };

    switch (corner.type) {
        case 'round':
            return { width: (corner.size - padding) * scale, height: (corner.size - padding) * scale };

        case 'angle': {
            const q = Math.tan(Math.PI / 8) - 1;

            const width = 'size' in corner ? corner.size : corner.width;
            const height = 'size' in corner ? corner.size : corner.height;

            return { width: (width + padding * q) * scale, height: (height + padding * q) * scale };
        }
    }
};

const getPath = (scale: number, padding: number = 0) => {
    const corners = props.corners ?? {};
    const { tl, tr, br, bl } = corners;

    const tlSize = getCornerSize(tl, padding, scale);
    const trSize = getCornerSize(tr, padding, scale);
    const brSize = getCornerSize(br, padding, scale);
    const blSize = getCornerSize(bl, padding, scale);

    const p = padding * scale;

    const w = width.value;
    const h = height.value;

    let result = M({ x: p + tlSize.width, y: p });

    result += L({ x: w - p - trSize.width, y: p });

    if (tr) {
        switch (tr.type) {
            case 'round':
                result += A({
                    rx: trSize.width,
                    ry: trSize.height,
                    xRotation: 0,
                    largeArc: 0,
                    sweep: 1,
                    x: w - p,
                    y: p + trSize.height,
                });
                break;

            case 'angle':
                result += L({ x: w - p, y: p + trSize.height });
                break;
        }
    }

    result += L({ x: w - p, y: h - p - brSize.height });

    if (br) {
        switch (br.type) {
            case 'round':
                result += A({
                    rx: brSize.width,
                    ry: brSize.height,
                    xRotation: 0,
                    largeArc: 0,
                    sweep: 1,
                    x: w - p - brSize.width,
                    y: h - p,
                });
                break;

            case 'angle':
                result += L({ x: w - p - brSize.width, y: h - p });
                break;
        }
    }

    result += L({ x: p + blSize.width, y: h - p });

    if (bl) {
        switch (bl.type) {
            case 'round':
                result += A({
                    rx: blSize.width,
                    ry: blSize.height,
                    xRotation: 0,
                    largeArc: 0,
                    sweep: 1,
                    x: p,
                    y: h - p - blSize.height,
                });
                break;

            case 'angle':
                result += L({ x: p, y: h - p - blSize.height });
                break;
        }
    }

    result += L({ x: p, y: p + tlSize.height });

    if (tl) {
        switch (tl.type) {
            case 'round':
                result += A({
                    rx: tlSize.width,
                    ry: tlSize.height,
                    xRotation: 0,
                    largeArc: 0,
                    sweep: 1,
                    x: p + tlSize.width,
                    y: p,
                });
                break;

            case 'angle':
                result += L({ x: p + tlSize.height, y: p });
                break;
        }
    }

    result += Z();

    return result;
};

const getCornerTrianglePath = (scale: number, position: CornerPosition, corner: AngleCorner) => {
    const size = {
        width: (corner.triangle ?? 0) * scale,
        height: (corner.triangle ?? 0) * scale,
    };

    const w = width.value;
    const h = height.value;

    switch (position) {
        case 'tl':
            return M({ x: size.width, y: 0 })
                + L({ x: 0, y: 0 })
                + L({ x: 0, y: size.height })
                + Z();

        case 'tr':
            return M({ x: w - size.width, y: 0 })
                + L({ x: w, y: 0 })
                + L({ x: w, y: size.height })
                + Z();

        case 'br':
            return M({ x: w - size.width, y: h })
                + L({ x: w, y: h })
                + L({ x: w, y: h - size.height })
                + Z();

        case 'bl':
            return M({ x: size.width, y: h })
                + L({ x: 0, y: h })
                + L({ x: 0, y: h - size.height })
                + Z();
    }
};

const getCenteredCrossPath = (scale: number, size: number) => {
    const w = width.value;
    const h = height.value;

    const s = size / 2 * scale;

    return M({ x: 0, y: 0 })
        + L({ x: w, y: 0 })
        + L({ x: w, y: h })
        + L({ x: 0, y: h })
        // Cut the cross in the center
        + M({ x: w / 2 + s, y: h / 2 - s })
        + L({ x: w, y: h / 2 - s })
        + L({ x: w, y: h / 2 + s })
        + L({ x: w / 2 + s, y: h / 2 + s })
        + L({ x: w / 2 + s, y: h })
        + L({ x: w / 2 - s, y: h })
        + L({ x: w / 2 - s, y: h / 2 + s })
        + L({ x: 0, y: h / 2 + s })
        + L({ x: 0, y: h / 2 - s })
        + L({ x: w / 2 - s, y: h / 2 - s })
        + L({ x: w / 2 - s, y: 0 })
        + L({ x: w / 2 + s, y: 0 })
        + Z();
};
</script>

<template>
    <div
        ref="container"
        class="clip-container"
    >
        <client-only>
            <template v-if="border">
                <svg
                    class="clip-container__border"
                    xmlns="http://www.w3.org/2000/svg"
                    :viewBox="`0 0 ${width} ${height}`"
                    :style="{
                        clipPath: border.type === 'dashed'
                            ? `path(evenodd, '${getCenteredCrossPath(scaleStore.scale, border.gap)}')`
                            : undefined,
                        ...borderStyle,
                    }"
                >
                    <path
                        :d="getPath(scaleStore.scale, border.width / 2)"
                        fill="#0000"
                        :stroke="`var(${borderColorVariable}, transparent)`"
                        :stroke-width="border ? border.width * scaleStore.scale : undefined"
                        :style="{ ...borderPathStyle }"
                        vector-effect="non-scaling-stroke"
                        :shape-rendering="borderShapeRendering"
                    />
                </svg>
            </template>

            <svg
                class="clip-container__path"
                xmlns="http://www.w3.org/2000/svg"
                :viewBox="`0 0 ${width} ${height}`"
                :style="{ ...backgroundStyle }"
            >
                <path
                    :d="getPath(scaleStore.scale, clip ==='border' ? border?.width : undefined)"
                    :fill="`var(${backgroundColorVariable}, transparent)`"
                    :style="{ ...backgroundPathStyle }"
                />
            </svg>

            <template v-if="corners">
                <svg
                    class="clip-container__path"
                    xmlns="http://www.w3.org/2000/svg"
                    :viewBox="`0 0 ${width} ${height}`"
                    :style="{ ...triangleStyle }"
                >
                    <template
                        v-for="position in ['tl', 'tr', 'br', 'bl'] as const"
                        :key="position"
                    >
                        <template
                            v-if="corners[position]
                                && corners[position].type === 'angle'
                                && corners[position].triangle !== undefined"
                        >
                            <path
                                :d="getCornerTrianglePath(scaleStore.scale, position, corners[position])"
                                :fill="`var(${triangleColorVariable}, transparent)`"
                                :style="{ ...trianglePathStyle }"
                            />
                        </template>
                    </template>
                </svg>
            </template>

            <div
                class="clip-container__content"
                :style="{
                    clipPath: clip
                        ? `path('${getPath(scaleStore.scale, clip ==='border' ? border?.width : undefined)}')`
                        : undefined,
                }"
            >
                <slot />
            </div>
        </client-only>
    </div>
</template>

<style lang="scss" scoped>
.clip-container {
    position: relative;

    &__border {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 3;
        pointer-events: none;
    }

    &__path {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 1;
    }

    &__content {
        position: absolute;
        width: 100%;
        height: 100%;

        z-index: 2;
    }
}
</style>
