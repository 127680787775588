export function M(params: { x: number, y: number}) {
    const { x, y } = params;

    return `M${x} ${y}`;
}

export function L(params: { x: number, y: number}) {
    const { x, y } = params;

    return `L${x} ${y}`;
}

export function A(params: { rx: number, ry: number, xRotation: number, largeArc: number, sweep: number, x: number, y: number}) {
    const { rx, ry, xRotation, largeArc, sweep, x, y } = params;

    return `A${rx} ${ry} ${xRotation} ${largeArc} ${sweep} ${x} ${y}`;
}

export function C(params: { x1: number, y1: number, x2: number, y2: number, x: number, y: number}) {
    const { x1, y1, x2, y2, x, y } = params;

    return `C${x1} ${y1} ${x2} ${y2} ${x} ${y}`;
}

export function Z() {
    return 'Z';
}
